<template>
  <div class="flex flex-col gap-y-4 items-center justify-center">
    <nav
      v-if="(!totalItems || totalItems > 0) && totalPages > 1"
      class="nami-pagination-wrap"
      aria-label="Pagination"
    >
      <button
        v-if="totalPages > DisplayedPageCount"
        @click="changePage(1)"
        class="nami-pagination-button"
        :class="currentPage === 1 && 'disabled'"
      >
        <span class="sr-only">Start</span>
        <IconChevronsLeft aria-hidden="true" />
      </button>

      <button
        v-for="page in pageModel"
        @click="changePage(page)"
        class="nami-pagination-button"
        :class="modelValue === page && 'active'"
      >
        {{ page }}
      </button>

      <button
        v-if="totalPages > DisplayedPageCount"
        @click="changePage(totalPages)"
        class="nami-pagination-button"
        :class="currentPage === totalPages && 'disabled'"
      >
        <span class="sr-only">End</span>
        <IconChevronsRight aria-hidden="true" />
      </button>
    </nav>
    <p class="text-sm sm:text-base" v-if="totalItems">
      {{ $t("misc.result", { count: Math.min(totalItems, 10000) }) }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { IconChevronsLeft, IconChevronsRight } from "@tabler/icons-vue";

interface Props {
  modelValue: number;
  totalPages: number;
  totalItems?: number;
  keepRoute?: boolean;
}

interface Events {
  (e: "update:modelValue", v: number): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Events>();
const route = useRoute();
const router = useRouter();

const t = useNuxtApp().$i18n.global.t;

const currentPage = computed(() => props.modelValue);

const DisplayedPageCount = 5;

const pageModel = computed(() => {
  const pages = Array.from(Array(props.totalPages + 1).keys());
  pages.splice(0, 1);
  let index = Math.min(
    pages.indexOf(props.modelValue) - Math.floor(DisplayedPageCount / 2),
    pages.length - DisplayedPageCount,
  );
  const displayedNums: number[] = [];
  while (displayedNums.length < DisplayedPageCount) {
    if (pages[index]) {
      displayedNums.push(pages[index]);
    }
    index++;
    if (index > pages.length) break;
  }
  return displayedNums;
});

function changePage(page: number) {
  if (!props.keepRoute)
    router.push({
      path: route.path,
      query: {
        ...route.query,
        page: page,
      },
    });
  if (props.modelValue === page) {
    // TODO: enter page
    // alert('enter page')
  }
  emit("update:modelValue", page);
}
</script>

<style scoped lang="postcss">
.nami-pagination-wrap {
  @apply isolate inline-flex -space-x-px rounded-md shadow-sm;

  & > :last-child {
    @apply rounded-r-md;
  }
  & > :first-child {
    @apply rounded-l-md;
  }
}

.nami-pagination-button {
  @apply relative inline-flex items-center justify-center h-10 w-10
  border border-neutral-300 dark:border-neutral-700
  text-sm font-medium
  bg-white dark:bg-neutral-800
  hover:opacity-75 active:opacity-50;

  &.active {
    @apply z-[3]
    text-nami-comi-blue
    dark:bg-neutral-700
    border-nami-comi-blue;
  }
}
</style>
